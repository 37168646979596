import React from 'react';
import atomize from "@quarkly/atomize";
import Iframe from "react-iframe";

const ReactIFrame = props => <Iframe
	{...props}
	url="https://docs.google.com/forms/d/e/1FAIpQLSeuEzphQCpSXfzpOcTtZODrtr-FIWHBADzYISP-QGgCWQvn_g/viewform?embedded=true"
	width="100%"
	height="800"
	id="myId"
	className="myClassname"
	display="initial"
	position="relative"
/>;

export default atomize(ReactIFrame)({
	name: "ReactIFrame",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "ReactIFrame — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});